// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-cavall-de-foc-js": () => import("./../src/pages/cavall-de-foc.js" /* webpackChunkName: "component---src-pages-cavall-de-foc-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-menor-de-edad-js": () => import("./../src/pages/menor-de-edad.js" /* webpackChunkName: "component---src-pages-menor-de-edad-js" */),
  "component---src-pages-vermut-ferrer-js": () => import("./../src/pages/vermut-ferrer.js" /* webpackChunkName: "component---src-pages-vermut-ferrer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

